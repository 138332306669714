import '../css/index.scss';

import {bindToolTips, makeAppHeaderSticky} from "../../common/js/dom-manipulation";
import {defineCountry, handleEvents} from "./events"
import {updateTiers} from "./dom-manipulation";
import {getCountry, getOlxRegionBasedOnLanguage} from "../../common/js/utils";

import Splide from "@splidejs/splide";
import '@splidejs/splide/css';
import {Video} from '@splidejs/splide-extension-video';
import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';


$(document).ready(function () {
    makeAppHeaderSticky()
    handleEvents()
    bindToolTips('.table-tooltip');
    defineCountry().then(r => console.log());

    let currentSlideNumber = 1; // Начинаем с первого слайда

    const splide = new Splide('.splide', {
        type: 'loop',
        autoplay: true,
        interval: 5000,
        speed: 1000,
        flickPower: 300,
        arrows: true,
        pagination: true,
        pauseOnHover: true,
        pauseOnFocus: true,
        easing: 'ease',
        padding: 0,
        video: {
            host: 'https://www.youtube-nocookie.com',
            loop: false,
            mute: false,
            disableOverlayUI: false,
            playerOptions: {
                htmlVideo: {
                    disableRemotePlayback: true,
                    crossOrigin: true,
                    preload: "metadata",
                    volume: 0.5,
                },
            },
        },
    })


    splide.on('video:play', function () {
        splide.Components.Autoplay.pause();
        splide.Components.Video.disable(true);

    });

    splide.on('video:pause video:ended', function () {
        splide.Components.Autoplay.play();
    });



    splide.on('move', function () {
        document.querySelectorAll('.splide video').forEach(video => {
            if (!video.paused) {
                video.pause();
                console.log('⏸ Видео остановлено при смене слайда');
            }
        });
    });


    splide.mount({Video});


    function initializeSlider() {
        // Подготовим слайды к началу работы
        slide2.style.transform = 'translateX(100%)'; // Второй слайд в позиции ожидания справа
    }

    function switchSlide() {
        const currentSlide = currentSlideNumber === 1 ? slide1 : slide2;
        const nextSlide = currentSlideNumber === 1 ? slide2 : slide1;

        // Анимация для текущего и следующего слайда
        nextSlide.style.transition = 'transform 1s ease';
        nextSlide.style.transform = 'translateX(0%)'; // Перемещаем следующий слайд на место текущего
        currentSlide.style.transform = 'translateX(-100%)'; // Выталкиваем текущий слайд

        // Возврат текущего слайда в позицию ожидания справа после анимации
        setTimeout(() => {
            currentSlide.style.transition = 'none'; // Отключаем анимацию для мгновенного перемещения
            currentSlide.style.transform = 'translateX(100%)'; // Перемещаем текущий слайд в позицию ожидания
        }, 1000); // Задержка, равная времени анимации

        // Подготавливаем слайды к следующей итерации
        currentSlideNumber = currentSlideNumber === 1 ? 2 : 1;
    }

//initializeSlider(); // Инициализация слайдера

// Первая смена слайда после начальной паузы
//setTimeout(switchSlide, 5000);

// Настройка интервала смены слайдов
// setInterval(() => {
//   switchSlide();
    // После смены слайда устанавливаем задержку перед следующей анимацией
    //  setTimeout(switchSlide, 5000);
// }, 10000); // Периодичность: время показа + анимация


})